<template>
  <div class="inventory-record">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @loadExpend="loadExpend"
    ></x-table>
  </div>
</template>

<script>
import CU from "@/common/util";
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖区",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.farmName + "/" + row.sceneName}</span>;
            },
          },
          {
            title: "养殖品种",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "变更数量(头)",
            minWidth: 100,
            key: "num",
          },
          {
            title: "变更类型",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.type == 1 ? "增加" : "减少"}</span>;
            },
          },
          {
            title: "描述",
            minWidth: 100,
            key: "describes",
          },
          {
            title: "上报时间",
            minWidth: 100,
            key: "createTime",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      farmScene: [],
      search_data: [],
      row: null
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          loadExpend: {
            loadExpendName: "导出",
          },
          width: 200,
          isBack: true,
          filterBox: [
            {
              conditionName: "变更类型",
              component: "select",
              field: "type",
              data: [
                { name: "增加", type: "1" },
                { name: "减少", type: "2" },
              ],
              parameterField: "type",
              showField: "name",
            },
            {
              conditionName: "上报日期",
              component: "date",
              field: "reportDate",
              defaultValue: [],
              type: "daterange",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.page.pageNo = 1;
      this.search_data = {
        ...data,
        beginTime: data.reportDate[0]
          ? moment(data.reportDate[0]).format("YYYY-MM-DD")
          : "",
        endTime: data.reportDate[1]
          ? moment(data.reportDate[1]).format("YYYY-MM-DD")
          : "",
      };
      delete this.search_data.reportDate;
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.LIVESTOCK_ON_HAND.RECORD_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        farmId: this.row.farmId,
        sceneId: this.row.sceneId,
        categoryId: this.row.categoryId,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      let data = this.table.data.map((row) => {
        let row_data = this.table.columns.map((el) => {
          if (el.key) {
            return row[el.key];
          } else if (el.title == "养殖区") {
            return row.farmName + "/" + row.sceneName;
          } else if (el.title == "变更类型") {
            return row.type == 1 ? "增加" : "减少";
          }
        });
        return row_data;
      });
      CU.exportExcel(columns, data, "存栏记录");
    },
  },
  mounted() {
    this.row = JSON.parse(this.$route.query.row)
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.inventory-record {
  width: 100%;
  height: 100%;
}
</style>